import { Paper, Stack } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { UserAccountsTable } from './UserAccountsTable'

export function UserAccountsPage() {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} padding={2} height="100%">
      <Paper sx={{ flex: 1 }}>
        <UserAccountsTable />
      </Paper>
      <Outlet />
    </Stack>
  )
}
