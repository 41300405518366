import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { AccountDto } from '../../api-client'
import AbcChip from '../../components/AbcChip'
import { useAlertService } from '../../lib/alerts'
import { useAccountsApi } from '../../lib/api-clients'

const columns: GridColDef<AccountDto>[] = [
  {
    field: 'displayName',
    headerName: 'Nom',
    width: 200,
    valueGetter: (_, account) => account.displayName,
  },
  {
    field: 'username',
    headerName: 'Identifiant ABC',
    width: 200,
    valueGetter: (_, account) => account.abcAccount?.username,
    renderCell: (params: GridRenderCellParams<AccountDto>) => <AbcChip username={params.row.abcAccount?.username} />,
  },
  {
    field: 'email',
    headerName: 'Adresse email administrateur',
    width: 200,
    valueGetter: (_, account) => account.adminAccount?.email,
  },
]

export function UserAccountsTable() {
  const alerts = useAlertService()
  const accountsApi = useAccountsApi()

  const [accounts, setAccounts] = useState<AccountDto[]>([])

  useEffect(() => {
    async function fetchAccounts() {
      try {
        const response = await accountsApi.getAccounts()
        setAccounts(response.accounts)
      } catch (error) {
        alerts.error('Erreur lors du chargement des comptes utilisateurs. Veuillez réessayer.')
        console.error('Error fetching data:', error)
      }
    }

    fetchAccounts()
  }, [alerts, accountsApi, setAccounts])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%', padding: 2 }}>
      <Box>
        <Typography component="strong" variant="h6">
          Comptes utilisateurs
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', flex: 1, width: '100%', height: '100%', minHeight: '600px', overflow: 'auto' }}>
        <DataGrid
          rows={accounts}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'displayName', sort: 'asc' }],
            },
          }}
          sx={{ position: 'absolute', width: '100%', height: '100%' }}
        />
      </Box>
    </Box>
  )
}
