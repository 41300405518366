import SendIcon from '@mui/icons-material/Send'
import { Box, Button, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { grey, lightBlue } from '@mui/material/colors'
import { compareAsc, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import Linkify from 'linkify-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AccountDto, ConversationDto, MessageDto } from '../../api-client'
import { useAlertService } from '../../lib/alerts'
import { useConversationsApi } from '../../lib/api-clients'
import merge from '../../lib/merge'

function formatDate(date: Date): string {
  return format(date, 'PPPPp', { locale: fr })
}

function byDateAsc(a: MessageDto, b: MessageDto): number {
  return compareAsc(a.createdDate, b.createdDate)
}

interface MessageProps {
  message: MessageDto
  previous: MessageDto | undefined
  ownerId: number
}

function Message(props: MessageProps) {
  const { message, previous, ownerId } = props

  const [previousDate, currentDate] = useMemo(
    () => [previous ? formatDate(previous.createdDate) : null, formatDate(message.createdDate)],
    [previous, message],
  )

  const showMessageAuthor = currentDate !== previousDate || message.author.displayName !== previous?.author.displayName

  const messageStyle = {
    padding: 1,
    borderRadius: 4,
    marginBottom: 1,
  }

  const messageReceivedStyle = {
    ...messageStyle,
    alignSelf: 'flex-start',
    marginRight: '25%',
    bgcolor: grey[100],
  }
  const messageSentStyle = {
    ...messageStyle,
    marginLeft: '25%',
    alignSelf: 'flex-end',
    bgcolor: lightBlue[100],
  }

  return (
    <>
      {currentDate !== previousDate && (
        <Box sx={{ padding: 1, textAlign: 'center' }}>
          <Typography variant="caption">{currentDate}</Typography>
        </Box>
      )}
      <Box sx={message.author.id === ownerId ? messageReceivedStyle : messageSentStyle}>
        {showMessageAuthor && <Typography variant="subtitle2">{message.author.displayName}</Typography>}
        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
          <Linkify as={React.Fragment}>{message.content}</Linkify>
        </Typography>
      </Box>
    </>
  )
}

interface MessageListProps {
  owner: AccountDto
  messages: MessageDto[]
}

function MessageList(props: MessageListProps) {
  const { owner, messages } = props

  const lastMessageRef = useRef<MessageDto>()
  const bottomRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const currentLastMessage = lastMessageRef.current
    const newLastMessage = messages.at(-1)

    if (currentLastMessage !== newLastMessage) {
      bottomRef.current?.scrollIntoView()
    }

    lastMessageRef.current = newLastMessage
  }, [messages])

  return (
    <Box flex={1} position="relative" minHeight="400px">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 2,
          overflow: 'scroll',
        }}>
        {messages.map((message, index) => (
          <Message key={message.id} message={message} previous={messages[index - 1]} ownerId={owner.id} />
        ))}
        <Typography ref={bottomRef} component="span" variant="overline" padding={4} alignSelf="center">
          Fin des messages
        </Typography>
      </Box>
    </Box>
  )
}

interface ConversationThreadProps {
  conversationId: number
}

export function ConversationThread(props: ConversationThreadProps) {
  const { conversationId } = props

  const alerts = useAlertService()
  const conversationsApi = useConversationsApi()

  const [conversation, setConversation] = useState<ConversationDto | undefined>(undefined)
  const [messages, setMessages] = useState<MessageDto[]>([])

  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)

  useEffect(() => {
    async function fetchConversation(since?: Date) {
      if (!conversationId) {
        return
      }

      try {
        const response = await conversationsApi.getConversation(conversationId, since)

        setConversation(response.conversation)
        setMessages(initial => merge(initial, response.conversation.messages).sort(byDateAsc))
      } catch (error) {
        alerts.error('Erreur lors du chargement de la conversations. Veuillez réessayer.')
        console.error('Error fetching data:', error)
      }
    }

    let since = new Date()
    fetchConversation()

    const intervalId = setInterval(() => {
      const now = new Date()
      fetchConversation(since)
      since = now
    }, 2000)
    return () => clearInterval(intervalId)
  }, [alerts, conversationsApi, conversationId])

  const handleSend = async () => {
    setSending(true)

    try {
      const response = await conversationsApi.postMessageIntoConversation(conversationId, { content: message })

      alerts.success('Le message a été envoyé.')

      setMessage('')
      setMessages(initial => merge(initial, [response.message]))
    } catch (e) {
      alerts.error('Erreur lors de l’envoi du message. Veuillez réessayer.')
      console.error('Could not send message', e)
    } finally {
      setSending(false)
    }
  }

  const handleMarkAsRead = async () => {
    await conversationsApi.markConversationAsReadByAdmin(conversationId)
  }

  if (!conversation) {
    return null
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography component="strong" variant="h6" sx={{ flex: 1 }}>
          Conversation avec {conversation.owner.displayName}
        </Typography>
        <Button variant="text" onClick={handleMarkAsRead}>
          Marquer comme lue
        </Button>
      </Box>
      <Divider />
      <MessageList owner={conversation.owner} messages={messages} />
      <Divider />
      <Box padding={2}>
        <TextField
          id="message"
          type="message"
          label="Envoyer un message"
          placeholder="Votre message..."
          variant="outlined"
          fullWidth
          multiline
          maxRows={5}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" aria-label="Envoyer" disabled={!message || sending} onClick={handleSend}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Box>
    </Box>
  )
}
