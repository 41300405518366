import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
} from '@mui/material'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlertService } from '../../lib/alerts'
import { useSecurityApi } from '../../lib/api-clients'
import { AuthenticatedUser, useAuthenticationService } from '../../lib/authentication'

export function LoginPage() {
  const navigate = useNavigate()

  const authentication = useAuthenticationService()
  const alerts = useAlertService()

  const securityApi = useSecurityApi()

  const [sending, setSending] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const disabled = sending || !email.trim() || !password.trim()

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSending(true)
    try {
      const response = await securityApi.authenticateAsAdmin({
        email,
        password,
      })
      const user: AuthenticatedUser = {
        accessToken: response.accessToken,
        displayName: email,
      }

      alerts.success('Vous êtes connecté(e).')

      authentication.setUser(user)
      navigate('/bonjour')
    } catch (e) {
      alerts.error('Identifiant ou mot de passe invalide. Veuillez réessayer.')
      console.error('Could not log into admin', e)
    } finally {
      setSending(false)
    }
  }

  const handleOpenResetPasswordDialog = async () => {
    setShowDialog(true)
  }

  const handleCloseResetPasswordDialog = () => {
    setShowDialog(false)
  }

  const handleSendResetPasswordLink = async () => {
    try {
      await securityApi.sendResetPasswordLink({ email })
      alerts.success('L’email a été envoyé.')
      setShowDialog(false)
    } catch (e) {
      alerts.error('L’envoi de l’email a échoué. Veuillez réessayer.')
      console.error('Could not send reset password link', e)
    }
  }

  return (
    <Box>
      <Paper sx={{ margin: 1 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={{ xs: 2, md: 4 }} sx={{ padding: { xs: 2, md: 4, lg: 8 } }}>
            <Typography variant="h4" component="h1">
              Connexion
            </Typography>
            <TextField
              label="Adresse email"
              type="email"
              id="username"
              name="username"
              autoComplete="username"
              variant="standard"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              label="Mot de passe"
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              autoComplete="current-password"
              variant="standard"
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={(_, checked) => setShowPassword(checked)} />}
                label="Afficher le mot de passe"
              />
            </FormGroup>
            <Button type="submit" variant="contained" size="large" disabled={disabled}>
              Se connecter
            </Button>
            <Typography variant="body2" component="p">
              Mot de passe oublié ou ne fonctionnant plus&nbsp;?
              <br />
              👉{' '}
              <Link component="button" type="button" onClick={handleOpenResetPasswordDialog}>
                Réinitialiser mon de passe
              </Link>
            </Typography>
          </Stack>
        </form>
      </Paper>
      <Dialog
        open={showDialog}
        onClose={handleCloseResetPasswordDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Réinitialiser mon mot de passe</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous allez recevoir par email un lien pour réinitialiser votre mot de passe.
          </DialogContentText>
          <TextField
            label="Adresse email"
            type="email"
            name="username"
            autoComplete="username"
            variant="standard"
            fullWidth
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetPasswordDialog}>Annuler</Button>
          <Button onClick={handleSendResetPasswordLink} autoFocus>
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
