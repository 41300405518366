import ForumIcon from '@mui/icons-material/Forum'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import grey from '@mui/material/colors/grey'
import { useState } from 'react'
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom'
import { useAlertService } from '../lib/alerts'
import { useAuthenticationService } from '../lib/authentication'

const drawerWidth = 240

interface MainMenuProps {
  onMenuItemClick?: () => void
}

function MainMenu(props: MainMenuProps): JSX.Element {
  const { onMenuItemClick } = props

  const navigate = useNavigate()

  const authentication = useAuthenticationService()
  const alerts = useAlertService()

  const handleLogOut = function () {
    alerts.info('Vous êtes déconnecté(e).')

    authentication.setUser(undefined)
    navigate('/login')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/bonjour" onClick={onMenuItemClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Accueil" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/conversations" onClick={onMenuItemClick}>
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="Conversations" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/utilisateurs" onClick={onMenuItemClick}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="Comptes utilisateurs" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/qrcode" onClick={onMenuItemClick}>
            <ListItemIcon>
              <QrCode2Icon />
            </ListItemIcon>
            <ListItemText primary="QR code" />
          </ListItemButton>
        </ListItem>
      </List>
      <Box sx={{ flex: 1 }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Se déconnecter" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
}

export function DashboardPage() {
  const authentication = useAuthenticationService()
  const [menuVisible, setMenuVisible] = useState(false)

  const toggleMenu = () => {
    setMenuVisible(visible => !visible)
  }

  if (!authentication.getUser()) {
    return <Navigate to="/login" />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
      }}>
      <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 1, display: { md: 'none' } }}
            aria-label={menuVisible ? 'Fermer le menu' : 'Ouvrir le menu'}
            title={menuVisible ? 'Fermer le menu' : 'Ouvrir le menu'}
            onClick={toggleMenu}>
            {menuVisible ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            TM36 Mon entreprise
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer open={menuVisible} onClose={toggleMenu} sx={{ display: { md: 'none' } }}>
        <Toolbar />
        <MainMenu onMenuItemClick={toggleMenu} />
      </Drawer>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          display: { xs: 'none', md: 'unset' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}>
        <Toolbar />
        <MainMenu />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: grey.A200,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}
